import React from "react";
import styled from "styled-components";
import { screen, innerWidth, mainCyan } from "../common/variables";

const Wrapper = styled.div`
  max-width: ${innerWidth};
  margin: 22px auto 0 auto;
  padding: 0 32px;

  @media ${screen.xsmall} {
    padding: 0 48px;
  }
  @media ${screen.medium} {
    padding: 0;
  }

  .title {
    color: ${mainCyan};
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0 0 25px 0;
  }

  .bars {
    display: flex;
    max-width: 400px;

    .selected {
      font-size: 0.937rem;
      border-top: 4px ${mainCyan} solid;
      padding: 12px 34px 0 10px;
      font-weight: 300;
      color: #fff;

      &:first-child {
        padding: 12px 34px 0 0;
      }

      &--not-selected {
        border-top: 4px #fff solid;
        color: #a8acb2;
      }
    }
  }
`;

const ProgressBar = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default ProgressBar;
