import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainNavy,
  innerWidth,
  mainViolet,
  screen,
} from "../../components/common/variables";
import Nav from "../../components/nav";
import StyledButton from "../../components/styled-button";
import ProgressBar from "../../components/progress-bar";
import BottomNav from "../../components/bottom-nav";
import { MdClear } from "react-icons/md";
import { motion } from "framer-motion";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 29,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainNavy};
  position: relative;
  overflow: auto;
  color: ${mainWhite};
  height: calc(100vh - 96px);
  padding: 0 0 50px 0;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    @media ${screen.xsmall} {
      padding: 140px 48px 0 48px;
    }
    @media ${screen.medium} {
      padding: 140px 0 0 0;
    }

    .texts {
      margin: 0 60px 0 0;
      max-width: 700px;
      width: 100%;

      h1 {
        color: ${mainViolet};
        font-size: 2.3rem;
        font-weight: 700;
        margin: 0 0 18px 0;
      }

      p {
        font-size: 1.12rem;
        margin: 0 0 16px 0;
      }

      ul {
        padding: 0 0 0 50px;
        li {
          list-style-type: disc;
          font-size: 1.12rem;
          margin: 0 0 14px 0;
        }
      }
    }

    .check-wrapper {
      .check-list {
        padding: 10px 0 0 0;

        &__each {
          display: flex;
          align-items: flex-start;
          margin: 0 0 14px 0;
          padding: 0 20px 0 0;

          .box {
            border: 2px #a8acb2 solid;
            width: 26px;
            height: 26px;
            margin: 6px 20px 0 0;

            cursor: pointer;
            position: relative;

            svg {
              font-size: 1.6rem;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .label {
            font-size: 1.4rem;
            width: 90%;
          }
        }
      }

      .btn {
        margin: 50px 0 0 0;

        &--disabled {
          a {
            background: #848b93;
            border: 2px #848b93 solid;
            color: #a5a9ad;
            pointer-events: none;
          }
        }
      }
    }
  }
`;

const DiscoverTwo = ({ data }) => {
  const [checkList, setCheckList] = useState([]);

  const handleCheck = (val) => {
    if (checkList.indexOf(val) === -1) {
      setCheckList([...checkList, val]);
    } else {
      const filtered = checkList.filter((item) => item !== val);
      setCheckList(filtered);
    }
  };

  const isChecked = (val) => {
    if (checkList.indexOf(val) !== -1) {
      return <MdClear />;
    } else {
      return null;
    }
  };

  console.log(checkList);
  return (
    <Layout>
      <SEO title="Manage - 2-5" />
      <Nav />
      <Wrapper initial="close" animate={"open"} variants={variantsContainer}>
        <motion.div variants={variantsList}>
          <ProgressBar>
            <h2 className="title">MANAGE</h2>
            <div className="bars">
              <p className="selected">1. Test</p>
              <p className="selected">2. Test against</p>
              <p className="selected selected--not-selected">3. Report</p>
            </div>
          </ProgressBar>
          <div className="inner-wrapper">
            <motion.div variants={variantsItem} className="texts">
              <h1>I want to test against…</h1>
            </motion.div>
            <motion.div variants={variantsItem} className="check-wrapper">
              <ul className="check-list">
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("July 2020 Cumulative Update")}
                  >
                    {isChecked("July 2020 Cumulative Update")}
                  </div>
                  <p className="label">July 2020 Cumulative Update</p>
                </li>
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("August 2020 Cumulative Update")}
                  >
                    {isChecked("August 2020 Cumulative Update")}
                  </div>
                  <p className="label">August 2020 Cumulative Update</p>
                </li>
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() =>
                      handleCheck("September 2020 Cumulative Update")
                    }
                  >
                    {isChecked("September 2020 Cumulative Update")}
                  </div>
                  <p className="label">September 2020 Cumulative Update</p>
                </li>
              </ul>

              <StyledButton
                className={checkList.length ? "btn" : "btn btn--disabled"}
                to="/manage/3-5/"
              >
                TEST
              </StyledButton>
            </motion.div>
          </div>
        </motion.div>

        <BottomNav
          leftLabel={"BACK"}
          leftLink={"/manage/1/"}
          rightLabel={"BACK TO START"}
          rightLink={"/"}
        />
      </Wrapper>
    </Layout>
  );
};

export default DiscoverTwo;
